import React, { useEffect, useState  } from 'react'
import { addDoc, collection,  doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL,listAll } from "firebase/storage";


export default function Features({showSpanish}) {

  const features = [
    {
      name: 'Website Design',
      description:
        `We create user-friendly web designs tailored to your target audience. Our team optimizes interfaces for all platforms while increasing site performance - it's our top priority!'`,
      href: 'https://gottawebdesign.com/website-design/',
      // icon: {imageData},
    },
    {
      name: 'Online stores',
      description:
        'Bring the global marketplace right to you with a fully functioning Woocommerce virtual store seamlessly integrated with debit, credit, or PayPal payment options. Customers have access to conveniences never before possible through virtual store technology.',
      href: 'https://gottawebdesign.com/store-online/',
      icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/shopping-online.svg",
    },
    {
      name: 'Virtual classrooms',
      description:
        'We develop interactive virtual classrooms to enable positive learning anytime, anywhere. Our customized strategies train digital instructors with visual media channels streaming live instruction & workshop assignments, plus data-collecting tools for student success.',
      href: 'https://gottawebdesign.com/virtual-classrooms/',
      icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/virtual.svg",
    },
    {
        name: 'Development Solutions',
        description:
          'Make the most of tailored software applications built with advanced strategies to solve business problems and fuel growth. Unlock powerful collaboration tools that help teams and businesses stay connected while achieving common goals quickly and efficiently.',
        href: 'https://gottawebdesign.com/software-development/',
        icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/web-development.svg",
      },
      {
        name: 'Compelling Content',
        description:
          'We craft content for SEO-boosting and client brand-emphasizing usage in various channels: blogs, press releases, stories, etc., to showcase products, services, and events. Guaranteed reader engagement!',
        href: 'https://gottawebdesign.com/virtual-classrooms/',
        icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/content.svg",
      },
  ]
  const features2 = [
    {
      name: 'Diseño de páginas web',
      description:
        `Creamos diseños web fáciles de usar y adaptados a su público objetivo. Nuestro equipo optimiza las interfaces para todas las plataformas mientras aumenta el rendimiento del sitio: ¡es nuestra principal prioridad!`,
      href: 'https://gottawebdesign.com/website-design/',
      icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/ux.svg",
    },
    {
      name: 'Tiendas en línea',
      description:
        'Lleve el mercado global directamente a usted con una tienda virtual Woocommerce en pleno funcionamiento e integrada perfectamente con opciones de pago de débito, crédito o PayPal. Los clientes tienen acceso a comodidades nunca antes posibles a través de la tecnología de tienda virtual.',
      href: 'https://gottawebdesign.com/store-online/',
      icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/shopping-online.svg",
    },
    {
      name: 'Aulas virtuales',
      description:
        'Desarrollamos aulas virtuales interactivas para permitir el aprendizaje positivo en cualquier momento y lugar. Nuestras estrategias personalizadas capacitan a instructores digitales con canales de medios visuales que transmiten instrucción en vivo y tareas de talleres, además de herramientas de recopilación de datos para el éxito de los estudiantes.',
      href: 'https://gottawebdesign.com/virtual-classrooms/',
      icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/virtual.svg",
    },
    {
        name: 'Soluciones de desarrollo',
        description:
          'Aproveche al máximo las aplicaciones de software personalizadas creadas con estrategias avanzadas para resolver problemas comerciales e impulsar el crecimiento. Desbloquee poderosas herramientas de colaboración que ayudan a los equipos y las empresas a mantenerse conectados mientras logran objetivos comunes de manera rápida y eficiente.',
        href: 'https://gottawebdesign.com/software-development/',
        icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/web-development.svg",
      },
      {
        name: 'Contenido atractivo',
        description:
          'Creamos contenido para impulsar el SEO y enfatizar la marca del cliente en varios canales: blogs, comunicados de prensa, historias, etc., para mostrar productos, servicios y eventos. ¡Participación garantizada del lector!',
        href: 'https://gottawebdesign.com/virtual-classrooms/',
        icon: "https://gottawebdesign.com/wp-content/uploads/2023/02/content.svg",
      },
  ]

  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-sky-400"> {showSpanish ? "Implementar más rápido" : "Deploy faster"} </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          {showSpanish ? "¡Dale vida a tu negocio en línea con Gotta Web Design!" : "Bring your business to life online with Gotta Web Design!"}
            
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300 text-justify" >
          {showSpanish ? "Las empresas luchan por establecer, mejorar y potenciar la presencia digital en línea. Sin un diseño moderno, un desarrollo adaptativo y estrategias de contenido creativo, las empresas no pueden atraer clientes potenciales y desarrollar sus negocios." 
          : "Businesses are struggling to establish, enhance, and empower online digital presence. Without modern design, adaptive development, and creative content strategies, companies cannot bring in potential customers and build their businesses."}
          
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300 text-justify">
          {showSpanish ? "Gotta Web Design ofrece servicios de diseño de sitios web y desarrollo de software para ayudar a las empresas a crear una presencia digital moderna que atraiga a más clientes. Con nosotros, podrá disfrutar de los beneficios del diseño contemporáneo, el desarrollo adaptativo y las estrategias de contenido creativo que diferenciarán su negocio de la competencia." 
          : "Gotta Web Design provides website design and software development services to help businesses create a modern digital presence that attracts more customers. With us, you can enjoy the benefits of contemporary design, adaptive development, and creative content strategies that will set your business apart from the competition."}
          
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {
              showSpanish ? 
              features2.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                  {/* <feature.icon className="h-5 w-5 flex-none text-sky-400" aria-hidden="true" /> */}
                  <img className='h-20' src={feature.icon} alt="" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-sky-400">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            )) :
            features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                  {/* <feature.icon className="h-5 w-5 flex-none text-sky-400" aria-hidden="true" /> */}
                  <img className='h-20' src={feature.icon} alt="" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-sky-400">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))

          }
          </dl>
        </div>
      </div>
    </div>
  )
}
