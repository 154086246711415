import React from "react"
import {
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import GetSuscription from "./pages/GetSuscription"
import Header from "./components/Header"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/Footer"
import Livechat from "./liveChat/Livechat";
import GetWebsite from "./pages/GetWebsite";
import PaymentSuscription from "./pages/PaymentSuscription";
import PageError from "./pages/PageError";
import PaymentWebsite from "./pages/PaymentWebsite";
import Home from "./pages/Home";
import PaymentLanding from "./pages/PaymentLanding";
import About from "./pages/About";
import SectionContact from "./components/SectionContact";

function App() {

  return (
    <PayPalScriptProvider 
    options={{ clientId: process.env.REACT_APP_PAYPAL, components: "buttons",  currency: "USD", }}
    >    
    <Router>      
      <Header />
      <Routes>
        <Route path="/"   element={<Home/>} />
        <Route path="/about"   element={<About/>} />
        <Route path="/contact"   element={<SectionContact/>} />
        <Route path="/get-website"   element={<GetWebsite/>} />
        <Route path="/get-suscription" element={<GetSuscription />} />
        <Route path="/payment-suscription" element={<PaymentSuscription />} />
        <Route path="/payment-suscription-landing" element={<PaymentLanding />} />
        <Route path="/payment-website" element={<PaymentWebsite />} />
        <Route path="*" element={<PageError />} />
      </Routes>
      <Footer />
      <ToastContainer
         position="top-right"
         autoClose={5000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         rtl={false}
         pauseOnFocusLoss
         draggable
         pauseOnHover
         theme="dark"
         />
    <ToastContainer />    
    </Router>
    <Livechat />
    </PayPalScriptProvider>
    
    
  );
}

export default App;
