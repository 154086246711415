import React, {useState, useRef} from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import Fab from '@mui/material/Fab';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import imageBanner from "../assets/svg/bannergwd-negrita.svg"

export default function SectionContact() {

  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);
  
  const[ showSpanish, setShowSpanish] = useState(true)
  const [changelabelLenguage, setChangeLabelLenguage] = useState("English")

  function name() {
    if(showSpanish === true){
      setShowSpanish(false)
      setChangeLabelLenguage("Spanish")
    }
    if(showSpanish === false){
      setShowSpanish(true)
      setChangeLabelLenguage("English")
    }
    
  }
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE,
        process.env.REACT_APP_TEMPLATE,
        form.current,
        process.env.REACT_APP_CURRENT
      )
      .then(
        (result) => {
          document.getElementById("contact-form").reset();
          toast.success("Message sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: darkTheme ? "dark" : "light",
          });
          console.log(result.text);
          setSendingMail(false);
        },
        (error) => {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: darkTheme ? "dark" : "light",
          });
          console.log(error.text);
          setSendingMail(false);
        }
      );
  };
  return (
    <section className="overflow-hidden bg-gray-900">        
    <div className="mt-10 relative isolate overflow-hidden pt-14">
    <img
    src={imageBanner}
      alt="Gotta Market Strategy"
      className="absolute inset-0 -z-10 h-full w-full object-cover"
    />
    <div
      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      aria-hidden="true"
    >
      <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>        
        <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
          <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">             
            <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                <Fab variant="extended" color='secondary' size='small'  onClick={name}>
                  <GTranslateIcon sx={{ mr: 1 }} />
                  {changelabelLenguage}
                </Fab>
              <h1 className="text-4xl mt-8 font-bold tracking-tight text-gray-100 sm:text-6xl">
              <span className="relative flex h-3 w-3 ">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
              </span> 
               {
                showSpanish ? 
                "Potencia tu Éxito con Nuestro Equipo Competente:"
                : "Empower Your Success with Our Competent Team:"
               }    
              </h1>
              <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none" >
              {
                showSpanish ? 
                "Tanto si buscas un sitio web informativo, como si estás listo para llevar a cabo tus ambiciones de comercio electrónico o necesitas resaltar en línea, completa el formulario. Nos comunicaremos contigo rápidamente. Estamos emocionados por conocer tu empresa y explorar cómo podemos transformar tus objetivos en logros tangibles."
                : "Whether you need an informative website, are ready to unleash your e-commerce dreams, or have an established online presence and require assistance to stand out, fill out the form. We'll get in touch with you promptly. We're eager to learn all about your business and discover how we can turn your goals into tangible achievements."
               }  
              </p>              
            </div>
            <div className='w-full max-w-xl lg:shrink-0 xl:max-w-2xl'>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6"  ref={form} id='contact-form' action="php/mail.php" method="post"  onSubmit={sendEmail} >
                <div>
                  <label htmlFor="fullName" className="block text-sm font-medium leading-6 text-white">
                    { showSpanish ? "Nombre completo" : "Full Name" }
                    
                  </label>
                  <div className="mt-2">
                    <input
                      id='fullName'
                      name="fullName"
                      type="text"
                      autoComplete="Full Name"
                      required
                      className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                  { showSpanish ? "Dirección de correo electrónico" : "Email address" }
                     
                  </label>
                  <div className="mt-2">
                    <input
                      id='email'
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-white">
                  { showSpanish ? "Número de teléfono" : "Phone Number" }
                    
                  </label>
                  <div className="mt-2">
                    <input
                      id='phoneNumber'
                      name="phoneNumber"
                      type="number"
                      autoComplete="Phone Number"
                      required
                      className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="companyName" className="block text-sm font-medium leading-6 text-white">
                  { showSpanish ? "Nombre de la empresa" : "Company Name" }
                    
                  </label>
                  <div className="mt-2">
                    <input
                    id='companyName'
                      name="companyName"
                      type="text"
                      autoComplete="Full Name"
                      required
                      className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="describe" className="block text-sm font-medium leading-6 text-white">
                  { showSpanish ? "Describe tu empresa" : "Describe Your Company" }
                    
                  </label>
                  <div className="mt-2">
                    <textarea
                      id='describeyourcompany'
                      name="describeyourcompany"
                      rows={5}
                      required
                      className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>    

                <div className="space-y-5">
                   <div className="relative flex items-start">
                     <div className="flex h-6 items-center">
                       <input
                         id="check"
                         aria-describedby="apt-check"
                         name="check"
                         checked={checked}
                         onChange={handleChange}
                         type="checkbox"
                         className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                       />
                     </div>
                     <div className="ml-3 text-sm leading-6">
                       <label htmlFor="dateTime" className="font-medium text-gray-200">
                       { showSpanish ? "Hablar con un representante" : "Talk to a representative" }
                       </label>
                      {
                        checked ? (
                          <>
                         
                          <div>
                             <label htmlFor="dateTime" className="block text-sm font-medium leading-6 text-white">
                               { showSpanish ? "Fecha" : "Date"}
                             </label>
                             <div className="mt-2">
                               <input
                                id='date'
                                 name="date"
                                 type="datetime-local"
                                 required
                                 min="2023-09-07T00:00"
                                 max="2024-09-14T00:00"
                                 className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                               />
                             </div>
                           </div>
                          </>
                        ) : ""
                      }
                     </div>
                   </div>
                </div>


                <div>
                  <button
                  id='submit-btn'
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                     {sendingMail ? (
                      <>
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm align-self-center me-2"
                        ></span>
                        Sending.....
                      </>
                    ) : (
                      <>Send Message</>
                    )}
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
            

            </div>
          </div>
        </div>
        </div>
        
</section>
  )
}
