import { useState, useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore';
import Fab from '@mui/material/Fab';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import "firebase/storage";
import { db } from '../firebase';


const stats = [
  { label: 'Lineas de Codigo', value: '25k+' },
  { label: 'Clientes Satisfechos', value: '526' },
  { label: 'Projectos', value: '526' },
]
const stats1 = [
  { label: 'Lines of code', value: '25k+' },
  { label: 'Satisfied customers', value: '526' },
  { label: 'projects', value: '526' },
]
const values = [
  {
    name: 'Be world-class',
    description:
      'Aut illo quae. Ut et harum ea animi natus. Culpa maiores et sed sint et magnam exercitationem quia. Ullam voluptas nihil vitae dicta molestiae et. Aliquid velit porro vero.',
  },
  {
    name: 'Share everything you know',
    description:
      'Mollitia delectus a omnis. Quae velit aliquid. Qui nulla maxime adipisci illo id molestiae. Cumque cum ut minus rerum architecto magnam consequatur. Quia quaerat minima.',
  },
  {
    name: 'Always learning',
    description:
      'Aut repellendus et officiis dolor possimus. Deserunt velit quasi sunt fuga error labore quia ipsum. Commodi autem voluptatem nam. Quos voluptatem totam.',
  },
  {
    name: 'Be supportive',
    description:
      'Magnam provident veritatis odit. Vitae eligendi repellat non. Eum fugit impedit veritatis ducimus. Non qui aspernatur laudantium modi. Praesentium rerum error deserunt harum.',
  },
  {
    name: 'Take responsibility',
    description:
      'Sit minus expedita quam in ullam molestiae dignissimos in harum. Tenetur dolorem iure. Non nesciunt dolorem veniam necessitatibus laboriosam voluptas perspiciatis error.',
  },
  {
    name: 'Enjoy downtime',
    description:
      'Ipsa in earum deserunt aut. Quos minus aut animi et soluta. Ipsum dicta ut quia eius. Possimus reprehenderit iste aspernatur ut est velit consequatur distinctio.',
  },
]
const team = [
  {
    name: 'Omar Siles',
    role: 'Co-Founder / CTO',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  },
  // More people...
]
const blogPosts = [
  {
    id: 1,
    title: 'Vel expedita assumenda placeat aut nisi optio voluptates quas',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Michael Foster',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  // More posts...
]


export default function About() {


    const [listing, setListing] = useState(null)
    const code = process.env.REACT_APP_GWD   

  const[ showSpanish, setShowSpanish] = useState(true)
  const [changelabelLenguage, setChangeLabelLenguage] = useState("English")

  function name() {
    if(showSpanish === true){
      setShowSpanish(false)
      setChangeLabelLenguage("Spanish")
    }
    if(showSpanish === false){
      setShowSpanish(true)
      setChangeLabelLenguage("English")
    }
    
  }
    
    useEffect(() => {
        async function fetchListing() {
            const docRef = doc(db, "contents",code)
            const docSnap = await getDoc(docRef)
            if(docSnap.exists()){
                setListing(docSnap.data())
            }
        }
        fetchListing()
        
    },[])

  return (
    <div className="bg-gray-900 mt-10 pb-10">
      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-500 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                <Fab variant="extended" color='secondary' size='small'  onClick={name}>
                      <GTranslateIcon sx={{ mr: 1 }} />
                      {changelabelLenguage}
                    </Fab>
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                   { showSpanish ? 'Da vida a tu visión con Gotta Market strategy' : 'Bring Your Vision to Life with Gotta Market strategy'}
                  </h1>
                  <p className="relative mt-6 text-lg leading-8 text-gray-400 sm:max-w-md lg:max-w-none text-justify">
                    { showSpanish ? 'Gotta Market strategy es una empresa profesional de diseño y desarrollo web que ha ofrecido servicios galardonados durante más de 10 años. Proporcionamos diseños web personalizados y soluciones de comercio electrónico, desarrollo de intranet, campañas de marketing digital, servicios de diseño gráfico y mantenimiento de sitios web, lo que sea necesario para garantizar una sólida presencia en línea. Nuestro equipo está altamente motivado para ofrecer productos de calidad cumpliendo con los plazos, desde la concepción hasta la implementación durante la realización del proyecto. Nuestra filosofía de trabajo ayuda a nuestros clientes a obtener total satisfacción en cada ocasión, con un servicio al cliente excelente como nuestra prioridad.' 
                    :'Gotta Market strategy is a professional web design and development company that has been offering award-winning services for over 10 years. We provide custom web designs and eCommerce solutions, intranet development, digital marketing campaigns, graphic design services, and website maintenance - whatever it takes to ensure you have a strong online presence. Our team is highly motivated to deliver quality products while adhering to deadlines from ideation to deployment during the project realization. Our work philosophy helps our clients get total satisfaction every time, with excellent customer service as our priority.'}
                  </p>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    {
                        listing &&
                        <video className="w-85 shadow-lg mt-10 text-center" autoPlay loop controls muted>
                        <source
                        className='clip w-full'
                          src={listing.aboutVideo}
                         
                          type="video/mp4" />
                       </video>
                    }
                   
                  
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <span className='text-center text-white'>{ showSpanish ? 'Factura gratuita' : 'Free Invoice'}</span>
                  {
                        listing &&
                        <video className="w-85 shadow-lg mt-10 text-center" autoPlay loop controls muted>
                        <source
                        className='clip w-full'
                          src={process.env.REACT_APP_VIDEO_INVOICE}
                         
                          type="video/mp4" />
                       </video>
                    }
                    
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{ showSpanish ? 'Nuestra Mision' : 'Our mission'}</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <h3 className="text-xl leading-8 text-gray-400">{ showSpanish ? 'Potenciando Presencia en Línea:' : 'Empowering Online Presence:'}</h3>
                <p className="text-xl leading-8 text-gray-400">
                { showSpanish ? 'En Gotta Market strategy, nuestra misión es potenciar negocios creando presencias en línea dinámicas y atractivas. Creemos que un sitio web bien diseñado es más que una presencia digital; es una herramienta poderosa para que los negocios se conecten con su audiencia y alcancen sus objetivos.' 
                : 'At Gotta Market strategy, our mission is to empower businesses by crafting dynamic and engaging online presences. We believe that a well-designed website is more than a digital presence; it is a powerful tool for businesses to connect with their audience and achieve their goals.'}
                </p>
                <div className="mt-10 max-w-xl text-base leading-7 text-gray-400">
                <h3 className="text-xl leading-8 text-gray-400"> { showSpanish ? 'Soluciones Innovadoras:' : 'Innovative Solutions:'}</h3>
                  <p>
                  { showSpanish ? 'Estamos dedicados a proporcionar soluciones innovadoras y personalizadas que van más allá del diseño web convencional. Nuestra misión es aprovechar las últimas tecnologías, tendencias de diseño y estrategias digitales para crear sitios web que no solo cumplan sino que superen las expectativas de nuestros clientes.' 
                  : `We are dedicated to providing innovative and tailored solutions that go beyond conventional web design. Our mission is to leverage the latest technologies, design trends, and digital strategies to create websites that not only meet but exceed our clients' expectations.`}
                  </p>
                <h3 className="mt-10 text-xl leading-8 text-gray-400"> { showSpanish ? 'Enfoque Centrado en el Cliente:' : 'Client-Centric Approach:'}</h3>
                  <p>
                  { showSpanish ? 'En el centro de nuestra misión está un enfoque centrado en el cliente. Nos esforzamos por comprender las necesidades y aspiraciones únicas de cada cliente, asegurándonos de que nuestros diseños web se alineen con su visión y objetivos. Nuestra misión es ser un socio confiable en el camino hacia el éxito en línea para cada negocio que servimos.' 
                  : 'Central to our mission is a client-centric approach. We strive to understand the unique needs and aspirations of each client, ensuring that our web designs align with their vision and objectives. Our mission is to be a reliable partner on the journey to online success for every business we serve.'}
                  </p>
                </div>
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {
                    showSpanish ? stats.map((stat) => (
                      <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                        <dt className="text-base leading-7 text-white">{stat.label}</dt>
                        <dd className="text-5xl font-semibold tracking-tight text-white">{stat.value}</dd>
                      </div>
                    )) : 
                    stats1.map((stat) => (
                      <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                        <dt className="text-base leading-7 text-white">{stat.label}</dt>
                        <dd className="text-5xl font-semibold tracking-tight text-white">{stat.value}</dd>
                      </div>
                    ))
                  }
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Image section */}
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          {
            listing &&
            <img
            src={listing.aboutImage}
            alt=""
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
          }
        </div>

        {/* Values section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Unleashing Digital Excellence with Gotta Market strategy </h2>
            <p className="mt-6 text-lg leading-8 text-gray-400 text-justify">
            In a digital landscape where first impressions matter, Gotta Market strategy  stands as your gateway to unparalleled online success. Why choose us? Because we blend innovation with experience, tailoring solutions that transcend expectations. From captivating web designs to cutting-edge development, our commitment to excellence ensures your brand not only thrives but leads in the ever-evolving digital realm. 
            Choose Gotta Market strategy  – where your vision meets our expertise.
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            { listing &&
            listing.about.map((value) => (
              <div key={value.name}>
                <dt className="font-semibold text-white">{value.name}</dt>
                <dd className="mt-1 text-gray-400  text-justify">{value.description}</dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Logo cloud */}
        <div className="relative isolate -z-10 mt-32 sm:mt-48">
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg className="h-[40rem] w-[80rem] flex-none stroke-gray-200" aria-hidden="true">
              <defs>
                <pattern
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  x="50%"
                  y="50%"
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" strokeWidth={0} />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)" />
            </svg>
          </div>
          {/* <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
              Trusted by the world’s most innovative teams
            </h2>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                alt="Transistor"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                alt="Reform"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                alt="Tuple"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                alt="SavvyCal"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                alt="Statamic"
                width={158}
                height={48}
              />
            </div>
          </div> */}
        </div>

        {/* Team section */}
        {/* <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our team</h2>
            <p className="mt-6 text-lg leading-8 text-gray-400">
              Sit facilis neque ab nulla vel. Cum eos in laudantium. Temporibus eos totam in dolorum. Nemo vel facere
              repellendus ut eos dolores similique.
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {team.map((person) => (
              <li key={person.name}>
                <img className="mx-auto h-24 w-24 rounded-full" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm leading-6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div> */}

        {/* Blog section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our blog</h2>
            <p className="mt-2 text-lg leading-8 text-gray-400">
            Dive into Insights, Inspiration, and Expertise.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {
              listing &&
              listing.blog.map((post) => (
              <article
                key={post.title}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                  <time dateTime={post.datetime} className="mr-8">
                    {post.date}
                  </time>
                  <div className="-ml-4 flex items-center gap-x-4">
                    <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <div className="flex gap-x-2.5">
                      <img src={post.authorImageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                      {post.authorName}
                    </div>
                  </div>
                </div>
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </main>
    </div>
  )
}