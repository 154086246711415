import React from 'react'

export default function GalleryBanner({showSpanish, data}) {
  

  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          {
            showSpanish === true ?
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Nuestros proyectos más populares</h2> 
            :
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our Most Popular Projects</h2> 

          }
          <p className="mt-6 text-lg leading-8 text-gray-300">
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
        >
          {
            data &&
          data.map((dato) => (
            <li key={dato.name}>
              <img className="aspect-[14/13] w-full rounded-2xl object-cover" src={dato.imageUrl} alt="" />
              {/* <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-white">{person.name}</h3>
              <p className="text-base leading-7 text-gray-300">{person.role}</p>
              <p className="text-sm leading-6 text-gray-500">{person.location}</p> */}
            </li>
          ))
          
          }
        </ul>
      </div>
    </div>
    // <div className='bg-white'>
    //  <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
    //   <div className="-m-1 flex flex-wrap md:-m-2">
    //     <div className="flex w-1/3 flex-wrap">
    //       <div className="w-full p-1 md:p-2">
    //         <img
    //           alt="gallery"
    //           className="block h-full w-full rounded-lg object-cover object-center"
    //           src="https://gottawebdesign.com/wp-content/uploads/2022/09/EmedyWelding.png" />
    //       </div>
    //     </div>
    //     <div className="flex w-1/3 flex-wrap">
    //       <div className="w-full p-1 md:p-2">
    //         <img
    //           alt="gallery"
    //           className="block h-full w-full rounded-lg object-cover object-center"
    //           src="https://gottawebdesign.com/wp-content/uploads/2022/09/EmedyWelding.png" />
    //       </div>
    //     </div>
    //     <div className="flex w-1/3 flex-wrap">
    //       <div className="w-full p-1 md:p-2">
    //         <img
    //           alt="gallery"
    //           className="block h-full w-full rounded-lg object-cover object-center"
    //           src="https://gottawebdesign.com/wp-content/uploads/2022/09/EmedyWelding.png" />
    //       </div>
    //     </div>
    //     <div className="flex w-1/3 flex-wrap">
    //       <div className="w-full p-1 md:p-2">
    //         <img
    //           alt="gallery"
    //           className="block h-full w-full rounded-lg object-cover object-center"
    //           src="https://gottawebdesign.com/wp-content/uploads/2022/09/GetPrimavera.png" />
    //       </div>
    //     </div>
    //     <div className="flex w-1/3 flex-wrap">
    //       <div className="w-full p-1 md:p-2">
    //         <img
    //           alt="gallery"
    //           className="block h-full w-full rounded-lg object-cover object-center"
    //           src="https://gottawebdesign.com/wp-content/uploads/2023/03/Green-Point-Lawn-Care-transparente.png" />
    //       </div>
    //     </div>
    //     <div className="flex w-1/3 flex-wrap">
    //       <div className="w-full p-1 md:p-2">
    //         <img
    //           alt="gallery"
    //           className="block h-full w-full rounded-lg object-cover object-center"
    //           src="https://gottawebdesign.com/wp-content/uploads/2022/07/landscaping.png" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // </div>
  )
}
