import React,{useState} from 'react'
import registration from "../assets/svg/registration.svg"
import responsive from "../assets/svg/responsive.svg"
import rocket from "../assets/svg/rocket.svg"

export default function SectionHowItWork({showSpanish}) {
  

  return (
    <>
    {
      showSpanish ? (

    <div className="bg-gray-900 container my-24 mx-auto md:px-6">
     <section className="mt-20 mb-20 text-center">
       <h2 className="mb-16 text-3xl font-bold  text-white">
       Cómo Funciona El<u className="text-primary  text-white"> Proceso</u>
       </h2>
       <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
         <div className="mb-12 md:mb-0">
           <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
               <img className='w-20' src={registration} alt='mySvgImage' />
           </div>
           <h5 className="mb-4 text-lg font-bold  text-white">Rellene nuestro breve formulario</h5>
           <p className="text-neutral-100 dark:text-neutral-300">
           Simplemente proporciónenos algunos detalles esenciales sobre los requisitos de su sitio web.
           </p>
         </div>

         <div className="mb-12 md:mb-0">
           <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
           <img className='w-20' src={responsive} alt='mySvgImage' />
           </div>
           <h5 className="mb-4 text-lg font-bold  text-white"> Creamos su nuevo sitio web</h5>
           <p className="text-neutral-100 dark:text-neutral-300">
           Construimos su nuevo sitio web y le entregamos un borrador. Tendrá una ventana de 30 días para enviar revisiones ilimitadas a Gotta Web Design, asegurando que su sitio web se alinee perfectamente con su visión.

           </p>
         </div>

         <div className="mb-12 md:mb-0">
           <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
           <img className='w-20' src={rocket} alt='mySvgImage' />
           </div>
           <h5 className="mb-4 text-lg font-bold  text-white">¡Se lanza su nuevo sitio web!</h5>
           <p className="text-neutral-100 dark:text-neutral-300">
           Después de su aprobación, lanzamos su sitio web, asegurándonos de que se envíe a los principales motores de búsqueda, incluidos Google, Yahoo, Bing y más.
           </p>
         </div>
       </div>
     </section>
  </div>
   ) : (
    <div className="bg-gray-900 container my-24 mx-auto md:px-6">
     <section className="mt-20 mb-20 text-center">
       <h2 className="mb-16 text-3xl font-bold  text-white">
       How the Process<u className="text-primary  text-white"> Works</u>
       </h2>
       <div className="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
         <div className="mb-12 md:mb-0">
           <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
               <img className='w-20' src={registration} alt='mySvgImage' />
           </div>
           <h5 className="mb-4 text-lg font-bold  text-white">Fill Out Our Brief Form</h5>
           <p className="text-neutral-100 dark:text-neutral-300">
           Simply provide us with some essential details regarding your website requirements.
           </p>
         </div>

         <div className="mb-12 md:mb-0">
           <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
           <img className='w-20' src={responsive} alt='mySvgImage' />
           </div>
           <h5 className="mb-4 text-lg font-bold  text-white"> We Craft Your Brand New Website</h5>
           <p className="text-neutral-100 dark:text-neutral-300">
           We construct your brand new website and deliver a draft to you. You'll have a 30-day window to submit unlimited revisions to Gotta Web Design, ensuring your website aligns perfectly with your vision.

           </p>
         </div>

         <div className="mb-12 md:mb-0">
           <div className="mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
           <img className='w-20' src={rocket} alt='mySvgImage' />
           </div>
           <h5 className="mb-4 text-lg font-bold  text-white">Your Fresh Website is Launched!</h5>
           <p className="text-neutral-100 dark:text-neutral-300">
           After your approval, we launch your website, ensuring it's submitted to all major search engines, including Google, Yahoo, Bing, and more.
           </p>
         </div>
       </div>
     </section>
  </div>
   )
  
}
  </>
  )
}
