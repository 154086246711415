import { useState, useEffect } from 'react'
import { Dialog, RadioGroup } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'


const pricing = {
  frequencies: [
    { value: 'monthly',   label: 'Mensual', priceSuffix: '/mes' },
    { value: 'annually', label: 'Anualmente', priceSuffix: '/año' },
  ],
  tiers: [
    {
      name: 'Landing Page',
      id: 'tier-freelancer',
      href: '/contact',
      price: { monthly: '$30', annually: '$240' },
      description: 'Una página web única y atractiva para promocionar sus productos y servicios.',
      features: ['Diseño personalizado de una página', 'Hosting y dominio incluidos', 'Entrega rápida en 7 días', 'Soporte técnico prioritario', 'Seguridad garantizada'],
      mostPopular: false,
    },
    {
      name: 'Website de 10 Páginas',
      id: 'tier-startup',
      href: '/contact',
      price: { monthly: '$49', annually: '$480' },
      description: 'Ideal para negocios y emprendimientos en continuo crecimiento y expansión.',
      features: [
        'Diseño personalizado de hasta 10 páginas',
        'Hosting y dominio incluidos',
        'Entrega rápida en 7 días',
        'Mantenimiento continuo y soporte técnico',
        'Flexibilidad en los cambios y ajustes',
      ],
      mostPopular: true,
    },
    {
      name: 'Personalizado',
      id: 'tier-enterprise',
      href: '/contact',
      price: { monthly: 'Precio a Consultar', annually: 'Precio a Consultar' },
      description: 'Ideal para tiendas en línea a gran escala y negocios complejos.',
      features: [
        'Diseño adaptable a proyectos más complejos',
        'Hosting y dominio personalizados',
        'Entrega y mantenimiento personalizados',
        'Soporte técnico extendido y asesoramiento especializado',
      ],
      mostPopular: false,
    },
  ],
}

const pricing1 = {
    frequencies: [
      { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
      { value: 'annually', label: 'Annually', priceSuffix: '/year' },
    ],
    tiers: [
      {
        name: 'Landing Page',
        id: 'tier-freelancer',
        href: '/contact',
        price: { monthly: '$30', annually: '$240' },
        description: 'A unique and engaging single web page to promote your products and services.',
        features: ['Custom design of a single page', 'Hosting and domain included', 'Fast delivery in 7 days', 'Priority technical support', 'Guaranteed security'],
        mostPopular: false,
      },
      {
        name: '10-Page Website',
        id: 'tier-startup',
        href: '/contact',
        price: { monthly: '$49', annually: '$480' },
        description: 'Ideal for businesses and ventures in continuous growth and expansion.',
        features: [
          'Custom design of up to 10 pages',
          'Hosting and domain included',
          'Fast delivery in 7 days',
          'Ongoing maintenance and technical support',
          'Flexibility in changes and adjustments',
        ],
        mostPopular: true,
      },
      {
        name: 'Custom',
        id: 'tier-enterprise',
        href: '/contact',
        price: { monthly: 'Price Upon Request', annually: 'Price Upon Request' },
        description: 'Ideal for large-scale online stores and complex businesses.',
        features: [
          'Adaptable design for more complex projects',
          'Custom hosting and domain',
          'Tailored delivery and maintenance',
          'Extended technical support and specialized guidance',
        ],
        mostPopular: false,
      },
    ],
  }


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pricing({showSpanish}) {

  const [frequency, setFrequency] = useState(pricing.frequencies[0])

    
  return (
    <div className="bg-gray-900">
        <section className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h1 className="text-base font-semibold leading-7 text-sky-400">{showSpanish ? "Decide el mejor plan para" : "Decide the best plan for you"}</h1>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
             {showSpanish ? "Desbloquea tu potencial con nuestras opciones de precios." : " Unlock Your Potential with Our Pricing Options"}
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
             {showSpanish ? "Selecciona el plan perfecto para cautivar a tu audiencia, fomentar la lealtad del cliente y aumentar las ventas, todo a un precio asequible." : "Select the perfect plan to captivate your audience, foster customer loyalty, and boost sales, all at an affordable price."}
          </p>
          <div className="mt-16 flex justify-center">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
            >
              <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
              {
                showSpanish ? 
                pricing.frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(checked ? 'bg-sky-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                )) :
                pricing1.frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(checked ? 'bg-sky-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                ))
              }
            </RadioGroup>
          </div>
          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {
                showSpanish ? 
                pricing.tiers.map((tier) => (
                    <div
                      key={tier.id}
                      className={classNames(
                        tier.mostPopular ? 'bg-white/5 ring-2 ring-sky-500' : 'ring-1 ring-white/10',
                        'rounded-3xl p-8 xl:p-10'
                      )}
                    >
                      <div className="flex items-center justify-between gap-x-4">
                        <h2 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                          {tier.name}
                        </h2>
                        {tier.mostPopular ? (
                          <p className="rounded-full bg-sky-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                            Más popular
                          </p>
                        ) : null}
                      </div>
                      <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                      <p className="mt-6 flex items-baseline gap-x-1">
                        <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                        <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                      </p>
                      <Link
                        to={tier.href}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-sky-500 text-white shadow-sm hover:bg-sky-400 focus-visible:outline-sky-500'
                            : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                          'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                        )}
                      >
                       Comprar plan
                      </Link>
                      <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                            {feature}
                          </li>
                        ))}                       
                      </ul>
                    </div>
                  )) : 
                  pricing1.tiers.map((tier) => (
                    <div
                      key={tier.id}
                      className={classNames(
                        tier.mostPopular ? 'bg-white/5 ring-2 ring-sky-500' : 'ring-1 ring-white/10',
                        'rounded-3xl p-8 xl:p-10'
                      )}
                    >
                      <div className="flex items-center justify-between gap-x-4">
                        <h2 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                          {tier.name}
                        </h2>
                        {tier.mostPopular ? (
                          <p className="rounded-full bg-sky-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                            Most popular
                          </p>
                        ) : null}
                      </div>
                      <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                      <p className="mt-6 flex items-baseline gap-x-1">
                        <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                        <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                      </p>
                      <Link
                        to={tier.href}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-sky-500 text-white shadow-sm hover:bg-sky-400 focus-visible:outline-sky-500'
                            : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                          'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                        )}
                      >
                        Buy plan
                      </Link>
                      <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                            {feature}
                          </li>
                        ))}                       
                      </ul>
                    </div>
                  ))
            }
          </div>
        </section>       
    </div>

  )
}