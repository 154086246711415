import React, { useState } from 'react'
import ButtonWrapper1 from '../components/paypal/ButtonWrapper1';
import { HandThumbUpIcon  } from '@heroicons/react/20/solid'
import "react-toastify/dist/ReactToastify.css";
import Fab from '@mui/material/Fab';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import logoHead from "../assets/images/logo-gwd-head512.png"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function PaymentLanding({darkTheme}) {


  const[ showSpanish, setShowSpanish] = useState(true)
  const [changelabelLenguage, setChangeLabelLenguage] = useState("English")
  const[show, setShow] = useState(false)
  const[label, setLabel] = useState( "Compra Ahora" )
  
function changeLabel() {
  setShow(true)
}

  function name() {
    if(showSpanish === true){
      setShowSpanish(false)
      setChangeLabelLenguage("Spanish")
      setLabel("Buy Now")
    }
    if(showSpanish === false){
      setShowSpanish(true)
      setChangeLabelLenguage("English")
      setLabel("Compra Ahora")
    }
    
  }
    
  const timeline = [
    {
      id: 1,
      content: 'Diseño Personalizado:',
      contenido: [ 
       { 
         name: "12", 
         data: "Cada sitio web se diseña de manera única para reflejar la identidad y la visión de tu marca."},
         {name: "13", 
         data: "Utilizamos las últimas tendencias de diseño para asegurarnos de que tu sitio sea atractivo y moderno."},

    ],
      target: 'Front End Developer',
      href: '#',
      date: 'Sep 20',
      datetime: '2020-09-20',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 2,
      content: 'Hosting y Dominio Incluidos:',
      contenido: [ 
        {
            name: "14", 
            data: "No te preocupes por encontrar un alojamiento confiable o un dominio adecuado; todo está cubierto en tu plan."},
        {
            name: "15", 
            data: "Garantizamos un tiempo de actividad óptimo para que tu sitio web esté siempre disponible para tus visitantes."},
 
     ],
      target: 'Bethany Blake',
      href: '#',
      date: 'Sep 22',
      datetime: '2020-09-22',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 3,
      content: 'Entrega Rápida:',
      contenido: [ 
        {
            name: "16", 
            data: "En solo 7 días, podrás tener tu sitio web completamente funcional y listo para atraer a tus clientes potenciales."},
        {
            name: "17", 
            data: "Nuestro equipo se compromete a cumplir con los plazos acordados para que puedas empezar a promocionar tu negocio lo antes posible."},
 
     ],
      target: 'Martha Gardner',
      href: '#',
      date: 'Sep 28',
      datetime: '2020-09-28',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 4,
      content: 'Sin Contratos ni Costos Ocultos:',
      contenido: [ 
        {
            name: "18", 
            data: "Creemos en la transparencia y la honestidad en nuestras transacciones comerciales. No hay sorpresas desagradables en tu factura."},
        {
            name: "19", 
            data: "No te obligamos a firmar contratos a largo plazo; la renovación es siempre tu elección."},
 
     ],
      target: 'Bethany Blake',
      href: '#',
      date: 'Sep 30',
      datetime: '2020-09-30',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 5,
      content: 'Mantenimiento Continuo:',
      contenido: [ 
        {
            name: "20", 
            data: "Realizamos actualizaciones periódicas para garantizar que tu sitio web funcione con la última tecnología y cumpla con los estándares de seguridad más recientes."},
        {
            name: "21", 
            data: "Realizamos copias de seguridad regularmente para proteger tus datos y contenido valioso."},
 
     ],
      target: 'Katherine Snyder',
      href: '#',
      date: 'Oct 4',
      datetime: '2020-10-04',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
        id: 6,
        content: 'Soporte Técnico Prioritario:',
        contenido: [ 
          {
              name: "22", 
              data: "Nuestro equipo de soporte está disponible para resolver cualquier problema técnico de manera oportuna y eficiente."},
          {
              name: "23", 
              data: "Respondemos rápidamente a tus consultas y nos aseguramos de que cualquier problema se resuelva con prontitud."},
   
       ],
        target: 'Katherine Snyder',
        href: '#',
        date: 'Oct 4',
        datetime: '2020-10-04',
        icon: HandThumbUpIcon,
        iconBackground: 'bg-blue-500',
      },
      {
        id: 7,
        content: 'Seguridad Garantizada:',
        contenido: [ 
          {
              name: "24", 
              data: "Implementamos medidas de seguridad avanzadas para proteger tu sitio web de amenazas potenciales y ataques cibernéticos."},
          {
              name: "25", 
              data: "Mantenemos un monitoreo constante para identificar y resolver posibles vulnerabilidades de seguridad."},
   
       ],
        target: 'Katherine Snyder',
        href: '#',
        date: 'Oct 4',
        datetime: '2020-10-04',
        icon: HandThumbUpIcon,
        iconBackground: 'bg-blue-500',
      },
      {
        id: 8,
        content: 'Flexibilidad en los Cambios:',
        contenido: [ 
          {
              name: "26", 
              data: "Realizamos ajustes y modificaciones según tus necesidades comerciales cambiantes para garantizar que tu sitio web siempre refleje la evolución de tu marca."},
          {
              name: "27", 
              data: "Te proporcionamos sugerencias y consejos para mejorar constantemente la experiencia de tus visitantes."},
   
       ],
        target: 'Katherine Snyder',
        href: '#',
        date: 'Oct 4',
        datetime: '2020-10-04',
        icon: HandThumbUpIcon,
        iconBackground: 'bg-blue-500',
      },
  ]

  const timeline1 = [
    {
      id: 1,
      content: 'Customized Design:',
      contenido: [ 
       { 
         name: "12", 
         data: "Each website is uniquely designed to reflect the identity and vision of your brand."},
         {name: "13", 
         data: "We use the latest design trends to ensure your site is appealing and modern."},

    ],
      target: 'Front End Developer',
      href: '#',
      date: 'Sep 20',
      datetime: '2020-09-20',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 2,
      content: 'Hosting and Domain Included:',
      contenido: [ 
        {
            name: "14", 
            data: "Don't worry about finding reliable hosting or a suitable domain; everything is covered in your plan."},
        {
            name: "15", 
            data: "We guarantee optimal uptime so your website is always available to your visitors."},
 
     ],
      target: 'Bethany Blake',
      href: '#',
      date: 'Sep 22',
      datetime: '2020-09-22',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 3,
      content: 'Fast Delivery:',
      contenido: [ 
        {
            name: "16", 
            data: "In just 7 days, you can have your fully functional website ready to attract your potential customers."},
        {
            name: "17", 
            data: "Our team is committed to meeting agreed-upon deadlines so you can start promoting your business as soon as possible."},
 
     ],
      target: 'Martha Gardner',
      href: '#',
      date: 'Sep 28',
      datetime: '2020-09-28',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 4,
      content: 'No Contracts or Hidden Costs:',
      contenido: [ 
        {
            name: "18", 
            data: "We believe in transparency and honesty in our business transactions. There are no unpleasant surprises in your bill."},
        {
            name: "19", 
            data: "We don't force you to sign long-term contracts; renewal is always your choice."},
 
     ],
      target: 'Bethany Blake',
      href: '#',
      date: 'Sep 30',
      datetime: '2020-09-30',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
      id: 5,
      content: 'Continuous Maintenance:',
      contenido: [ 
        {
            name: "20", 
            data: "We perform regular updates to ensure your website works with the latest technology and meets the latest security standards."},
        {
            name: "21", 
            data: "We regularly back up to protect your valuable data and content."},
 
     ],
      target: 'Katherine Snyder',
      href: '#',
      date: 'Oct 4',
      datetime: '2020-10-04',
      icon: HandThumbUpIcon,
      iconBackground: 'bg-blue-500',
    },
    {
        id: 6,
        content: 'Priority Technical Support:',
        contenido: [ 
          {
              name: "22", 
              data: "Our support team is available to resolve any technical issues in a timely and efficient manner."},
          {
              name: "23", 
              data: "We respond quickly to your inquiries and ensure that any issues are resolved promptly."},
   
       ],
        target: 'Katherine Snyder',
        href: '#',
        date: 'Oct 4',
        datetime: '2020-10-04',
        icon: HandThumbUpIcon,
        iconBackground: 'bg-blue-500',
      },
      {
        id: 7,
        content: 'Guaranteed Security:',
        contenido: [ 
          {
              name: "24", 
              data: "We implement advanced security measures to protect your website from potential threats and cyber-attacks."},
          {
              name: "25", 
              data: "We maintain constant monitoring to identify and resolve potential security vulnerabilities."},
   
       ],
        target: 'Katherine Snyder',
        href: '#',
        date: 'Oct 4',
        datetime: '2020-10-04',
        icon: HandThumbUpIcon,
        iconBackground: 'bg-blue-500',
      },
      {
        id: 8,
        content: 'Flexibility in Changes:',
        contenido: [ 
          {
              name: "26", 
              data: "We make adjustments and modifications according to your changing business needs to ensure that your website always reflects the evolution of your brand."},
          {
              name: "27", 
              data: "We provide you with suggestions and advice to constantly improve the experience of your visitors."},
   
       ],
        target: 'Katherine Snyder',
        href: '#',
        date: 'Oct 4',
        datetime: '2020-10-04',
        icon: HandThumbUpIcon,
        iconBackground: 'bg-blue-500',
      },
  ];


  return (
    <>    
    <section className="flex mt-32 bg-gray-900 min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                  className="mx-auto h-20 w-auto"
                  src={logoHead}
                  alt="Gotta Market Strategy"
                />
                <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-white">
                 {showSpanish ? "Obtener una Suscripción" : "Get a Suscription"} 
                </h2>
                <h2 className="mt-2 text-center font-semibold leading-6 text-sky-400 hover:text-sky-300">
                $ 30.00
                </h2>            
              </div>

              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">           
              <div >          
              <button 
              onClick={changeLabel} 
              type='button'
              className="flex mb-10 w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >{label}</button>
              {
                show ? (
                <ButtonWrapper1 type="subscription"  />
                ) : <h3> </h3>
              }
              </div>
              
              <Fab variant="extended" color='secondary' size='small'  onClick={name}>
                   <GTranslateIcon sx={{ mr: 1 }} />
                   {changelabelLenguage}
              </Fab>
          </div>
    </section>   
    {
        showSpanish === true ?
        <section className="flex bg-gray-900 min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div className="flow-root sm:mx-auto sm:w-full sm:max-w-sm">
      <ul role="list" className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-sky'
                    )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <span className='text-sm text-sky-400 text-justify'> {event.content}{' '}</span>
                      <ul className="text-sm text-gray-500 text-justify">
                         {
                            event.contenido.map(x => (<li key={x.name}>{x.data}</li>))
                         }
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    </section> : 
    <section className="flex bg-gray-900 min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div className="flow-root sm:mx-auto sm:w-full sm:max-w-sm">
      <ul role="list" className="-mb-8">
        {timeline1.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-sky'
                    )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <span className='text-sm text-sky-400 text-justify'> {event.content}{' '}</span>                     
                      <ul className="text-sm text-gray-500 text-justify">
                         {
                            event.contenido.map(x => (<li key={x.name}>{x.data}</li>))
                         }
                      </ul>
                  </div>                 
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
    </section>
    }
    </>
  )
}
