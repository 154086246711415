import { useEffect, useState } from "react";
import {
	PayPalButtons,
	usePayPalScriptReducer
} from "@paypal/react-paypal-js";

const ButtonWrapper1 = ({ type }) => {

	const [{ options }, dispatch] = usePayPalScriptReducer();

	useEffect(() => {
        dispatch({
            type: "resetOptions",			
            value: {
                ...options,
                intent:type,
				vault:true
			},
        });
    }, [type]);
	return (<PayPalButtons
		
		createSubscription={(data, actions) => {
			return actions.subscription
				.create({
					plan_id: process.env.REACT_APP_PAYPAL_S_L
					
				})
				.then((orderId) => {
					// Your code here after create the order
					return orderId;
				});
		}}
		style={{
			label: "subscribe",
		}}
	/>);
}

export default ButtonWrapper1