import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "Do I have to pay for updates?",
    answer:
      "No, not at all! All our plans at Gotta Market Strategy include monthly maintenance, ensuring your website remains fresh and adaptable to your business's evolving needs. As a cloud-based service, when we introduce new features, they automatically become available in your account!",
  },
  {
    question: "Is hosting included?",
    answer:
      "Certainly! Every website at Gotta Market Strategy comes with secure, fully-managed hosting. We also perform daily backups of your data to guarantee you never experience any data loss.",
  },
  {
    question: "What sets Gotta Market Strategy apart from my current provider?",
    answer:
      "At Gotta Market Strategy, we create agency-quality, mobile-friendly websites. Our award-winning customer support team is committed to providing top-notch service at an incredibly affordable price. We respond promptly to all your inquiries and requests (within 24 hours - that's a guarantee), and we offer guidance to help you generate sales and leads through your website. Our 100% satisfaction guarantee ensures you'll love your website, or we'll refund your money - and we stand by that promise!",
  },
  {
    question: "How will you help boost my business?",
    answer:
      "Boosting your business takes more than just having a beautiful website; you need to generate leads and sales. Gotta Market Strategy makes it easy for potential customers to get in touch with you, request quotes, or schedule consultations. We prominently feature Calls to Actions (CTAs) throughout your site, ensure easy navigation, offer plugins to showcase your products and services, and deliver a professional design.",
  },
  {
    question: "What are the contract terms?",
    answer:
      "Gotta Market Strategy doesn't tie you down with long-term contracts. You have the flexibility to choose annual payment for maximum savings or opt for monthly billing with a minimal 6-month commitment. We also don't charge any setup fees, so the price you pay today remains the same each month.",
  },
  {
    question: "Can I use my own domain name?",
    answer:
      "Absolutely! Your plan at Gotta Market Strategy allows you to use your website with a custom domain.",
  },
  {
    question: "How long does it take to make changes to my site?",
    answer:
      "Gotta Market Strategy is known for its lightning-fast response times. We'll respond to your requests or questions within 24 hours, often even faster. We work diligently to implement your changes and will keep you updated throughout the process.",
  },
  {
    question: "Is Gotta Market Strategy a US-Based company with English-Speaking support?",
    answer:
      "Yes, indeed! Gotta Market Strategy is proudly based in the United States, with teams located across the country to assist in developing and maintaining your website. You'll work with a dedicated Project Management team, ensuring you never have to repeat yourself, and they'll fully understand your business and your needs!",
  },
  // More questions...
]

const faqs1 = [
  {
    question: "¿Tengo que pagar por las actualizaciones?",
    answer:
      "¡No, para nada! Todos nuestros planes en Gotta Market Strategy incluyen mantenimiento mensual, asegurando que tu sitio web permanezca fresco y adaptable a las necesidades cambiantes de tu negocio. Como servicio basado en la nube, cuando introducimos nuevas características, automáticamente están disponibles en tu cuenta.",
  },
  {
    question: "¿El hosting está incluido?",
    answer:
      "¡Claro! Cada sitio web en Gotta Market Strategy viene con hosting seguro y completamente administrado. También realizamos copias de seguridad diarias de tus datos para garantizar que nunca pierdas información.",
  },
  {
    question: "¿Qué diferencia a Gotta Market Strategy de mi proveedor actual?",
    answer:
      "En Gotta Market Strategy creamos sitios web de calidad de agencia, compatibles con dispositivos móviles. Nuestro equipo de soporte al cliente galardonado se compromete a brindar un servicio de alta calidad a un precio increíblemente asequible. Respondemos de manera rápida a todas tus consultas y solicitudes (en un plazo de 24 horas, eso está garantizado) y ofrecemos orientación para ayudarte a generar ventas y leads a través de tu sitio web. Nuestra garantía del 100% de satisfacción asegura que te encantará tu sitio web, o te reembolsaremos tu dinero, y cumplimos esa promesa.",
  },
  {
    question: "¿Cómo ayudarán a impulsar mi negocio?",
    answer:
      "Impulsar tu negocio requiere más que tener un sitio web bonito; necesitas generar leads y ventas. Gotta Market Strategy facilita que los posibles clientes se pongan en contacto contigo, soliciten cotizaciones o programen consultas. Destacamos llamados a la acción (CTAs) en todo tu sitio, garantizamos una navegación sencilla, ofrecemos complementos para mostrar tus productos y servicios y entregamos un diseño profesional.",
  },
  {
    question: "¿Cuáles son los términos del contrato?",
    answer:
      "Gotta Market Strategy no te ata con contratos a largo plazo. Tienes la flexibilidad de elegir el pago anual para obtener el máximo ahorro o optar por facturación mensual con un compromiso mínimo de 6 meses. Además, no cobramos tarifas de configuración, por lo que el precio que pagas hoy es el mismo cada mes.",
  },
  {
    question: "¿Puedo usar mi propio nombre de dominio?",
    answer:
      "¡Absolutamente! Tu plan en Gotta Market Strategy te permite utilizar tu sitio web con un nombre de dominio personalizado.",
  },
  {
    question: "¿Cuánto tiempo se tarda en realizar cambios en mi sitio?",
    answer:
      "Gotta Market Strategy es conocido por su tiempo de respuesta ultrarrápido. Responderemos a tus solicitudes o preguntas en un plazo de 24 horas, a menudo incluso más rápido. Trabajamos diligentemente para implementar tus cambios y te mantendremos actualizado durante todo el proceso.",
  },
  {
    question: "¿Gotta Market Strategy es una empresa con base en EE. UU. con soporte en inglés?",
    answer:
      "¡Así es! Gotta Market Strategy tiene su sede con orgullo en los Estados Unidos, con equipos ubicados en todo el país para ayudar en el desarrollo y mantenimiento de tu sitio web. Trabajarás con un equipo de gestión de proyectos dedicado, lo que garantiza que nunca tendrás que repetirte y ellos comprenderán completamente tu negocio y tus necesidades.",
  },
  // Más preguntas...
];


export default function SectionFaqs({showSpanish}) {
  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white"> {showSpanish ? "Preguntas frecuentes" : "Frequently Asked Questions" } </h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {
              showSpanish ?
            faqs1.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" color='#38bdf8'/>
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" color='#38bdf8' />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-300">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )) :
            faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" color='#38bdf8'/>
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" color='#38bdf8' />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-300">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))
            
            }
          </dl>
        </div>
      </div>
    </div>
  )
}
